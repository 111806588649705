(function() {
    new Swiper("[data-slider='offers']", {
        speed: 1500,
        autoplay: 5000,
        direction: 'horizontal',
        loop: true,
        pagination: '.offer-pagination',
        paginationClickable: true,
        nextButton: "[data-slider-buttons='offers-next']",
        prevButton: "[data-slider-buttons='offers-prev']",

        slidesPerView: 4,
        breakpoints: {
            800: {
                slidesPerView: 1
            },
            1200: {
                slidesPerView: 2
            },
            1500: {
                slidesPerView: 3
            }
        }
    });
})();

(function() {
    new Swiper("[data-slider='partners']", {
        speed: 1500,
        autoplay: 1500,
        direction: 'horizontal',
        loop: true,
//        pagination: '.offer-pagination',
//        paginationClickable: true,
//        nextButton: "[data-slider-buttons='offers-next']",
//        prevButton: "[data-slider-buttons='offers-prev']",

        slidesPerView: 5,
        breakpoints: {
            800: {
                slidesPerView: 2
            },
            1200: {
                slidesPerView: 5
            },
            1500: {
                slidesPerView: 5
            }
        }
    });
})();

(function() {
    $("[data-matchheight='advantage-item__title']").matchHeight({
        byRow: false,
        property: 'height',
    });
    $("[data-matchheight='advantage-item']").matchHeight();
})();
(function() {
    $("[data-matchheight='home-news__heading']").matchHeight({
        byRow: false,
        property: 'height',
    });
    $("[data-matchheight='home-news']").matchHeight();
})();


(function() {
    $("[data-matchheight='certificate-item']").matchHeight();
})();
(function() {
    $("[data-matchheight='blog-post-panel']").matchHeight();
})();

(function() {
    new Swiper("[data-slider='estates']", {
        speed: 2000,
        autoplay: 5000,
        loop: true,
        pagination: '.estate-pagination',
        slidesPerView: 'auto',
        paginationClickable: true,
        spaceBetween: "20%",
        nextButton: "[data-slider-buttons='estate-next']",
        prevButton: "[data-slider-buttons='estate-prev']",
        breakpoints: {
            768: {
                spaceBetween: "0%"
            }
        }
    });
})();

(function() {
    var offer_slider_top = new Swiper('#offer-slider-top', {
        nextButton: '#offer-slider-top-next',
        prevButton: '#offer-slider-top-prev',
        autoplay: 4000,
        speed: 2000,
    });

    var offer_slider_thumbs = new Swiper('#offer-slider-thumbs', {
        nextButton: '#offer-slider-thumbs-next',
        prevButton: '#offer-slider-thumbs-prev',
        spaceBetween: 30,
        centeredSlides: true,
        slidesPerView: 5,
        touchRatio: 0.2,
        slideToClickedSlide: true,
        speed: 2000,
    });

    offer_slider_top.params.control = offer_slider_thumbs;
    offer_slider_thumbs.params.control = offer_slider_top;
})();

(function() {
    var $galleries_top = $('.offers-gallery-top');
    var $galleries_thumbs = $('.offers-gallery-thumbs');
    var $swiper_buttons_next = $('.swiper-button-next');
    var $swiper_buttons_prev = $('.swiper-button-prev');

    $galleries_top.each(function(index, element) {
        var top = new Swiper($(this), {
            nextButton: $swiper_buttons_next.eq(index),
            prevButton: $swiper_buttons_prev.eq(index),
            spaceBetween: 10,
            speed: 2000,
        });
        var thumbs = new Swiper($galleries_thumbs.eq(index), {
            spaceBetween: 10,
            slidesPerView: 4,
            speed: 2000
        });

        top.params.control = thumbs;
        thumbs.params.control = top;
    });
})();

$(".pakiet-wrap").each(function(index) {

    var $wrap = $(this),
            $pakiet = $wrap.find(">.pakiet"),
            $button = $wrap.find(">.pakiet-more");

    $button.on("click", function() {

        var state = $(this).attr('data-click-state');

        if (state == 1) //zwin
        {
            $(this).html("ROZWIŃ WIĘCEJ <img src='/img/icon-arrow-down-black.png' />");
            $(this).attr('data-click-state', 0);

            $pakiet.switchClass("pakiet-background-hide", "pakiet-background-show", 400);


            $wrap.switchClass("show-pakiet-wrap", "hide-pakiet-wrap", 400);

        } else //Rozwin
        {

            $(this).html("ZWIŃ <img src='/img/icon-arrow-up-black.png' />");
            $(this).attr('data-click-state', 1);

            $pakiet.switchClass("pakiet-background-show", "pakiet-background-hide", 400);

            $wrap.switchClass("hide-pakiet-wrap", "show-pakiet-wrap", 400);
        }
    });

});

$("[name='order']").on('change', function() {

    var current_url = location.href;

    var parts = $(this).val().split('-');

    var order_by = parts[0];
    var order_as = parts[1];

    if (current_url.indexOf('?') == -1)
    {
        document.location.assign(current_url + '?order-by=' + order_by + '&order-as=' + order_as);
        return;
    }

    var regex_order_by = /order-by=([a-zA-Z0-9_])*/;
    var regex_order_as = /order-as=(asc|desc)*/;

    if (regex_order_by.test(current_url))
    {
        current_url = current_url.replace(regex_order_by, 'order-by=' + order_by);
    } else
    {
        current_url += '&order-by=' + order_by;
    }

    if (regex_order_as.test(current_url))
    {
        current_url = current_url.replace(regex_order_as, 'order-as=' + order_as);
    } else
    {
        current_url += '&order-as=' + order_as;
    }

    document.location.assign(current_url);
});

(function() {
    if (document.getElementById('estates-links'))
    {
        document.getElementById('estates-links').onclick = function(event) {
            event = event || window.event;
            var target = event.target || event.srcElement,
                    link = target.src ? target.parentNode : target,
                    options = {index: link, event: event},
                    links = this.getElementsByTagName('a');
            blueimp.Gallery(links, options);
        };
    }

})();

(function() {
    if (document.getElementById('certificate-links'))
    {
        document.getElementById('certificate-links').onclick = function(event) {
            event = event || window.event;
            var target = event.target || event.srcElement,
                    link = target.src ? target.parentNode : target,
                    options = {index: link, event: event},
                    links = this.getElementsByTagName('a');
            blueimp.Gallery(links, options);
        };
    }

})();

$(function() {
    $("#modal-contact-form").on('submit', function(e) {
        e.preventDefault();
        var $modal = $('#modal-contact');
        var $errors = $("[data-modal-error]");
        var $form = $("[data-modal='form']");
        var $success = $("[data-modal='success']");
        var $input_token = $("[name='_token']");
        var $input_name = $("[name='name']");
        var $input_email = $("[name='email']");
        var $input_subject = $("[name='subject']");
        var $input_content = $("[name='content']");
        var $captcha = $("[name='captcha']");
        var $captchaKey = $("[name='captcha_key']");
        

        var rodo1 = "";
        var rodo2 = "";
        var rodo3 = "";
        var rodo4 = "";
        
        if ($("[name='rodo1']:checked").length) {
            rodo1 = $("[name='rodo1']:checked").val();
        }
        if ($("[name='rodo2']:checked").length) {
            rodo2 = $("[name='rodo2']:checked").val();
        }
        if ($("[name='rodo3']:checked").length) {
            rodo3 = $("[name='rodo3']:checked").val();
        }
        if ($("[name='rodo4']:checked").length) {
            rodo4 = $("[name='rodo4']:checked").val();
        }

        $modal.on("hidden.bs.modal", function() {
            $form.show();
            $success.hide();
            $errors.hide();
        });

        $.ajax({
            url: "kontakt/formularz",
            method: "POST",
            dataType: "json",
            data: {
                "_token": $input_token.val(),
                name: $input_name.val(),
                email: $input_email.val(),
                subject: $input_subject.val(),
                content: $input_content.val(),
                rodo1: rodo1,
                rodo2: rodo2,
                rodo3: rodo3,
                rodo4: rodo4,
                captcha: $captcha.val(),
                captcha_key: $captchaKey.val()
            }
        }).always(function(response) {
            if (response.status == 200) {
                $errors.hide();
                $success.show();
                $form.hide();
            } else {
                $errors.each(function() {
                    if (response.responseJSON[$(this).data('modal-error')]) {
                        $(this).text(response.responseJSON[$(this).data('modal-error')]).show();
                    } else {
                        $(this).hide();
                    }
                });
            }
        });
    });
});

$(function() {
    $(".newsletter-form").on('submit', function(e) {
        e.preventDefault();
        var $errors = $("[data-modal-error]");
        var $success = $("[data-modal='success']");
        var $input_token = $("[name='_token']");
        var $input_email = $(".newsletter-form .email");
        var rodo1 = "";
        var rodo2 = "";
        var rodo3 = "";
        var rodo4 = "";
        
        if ($("[name='rodo1']:checked").length) {
            rodo1 = $("[name='rodo1']:checked").val();
        }
        if ($("[name='rodo2']:checked").length) {
            rodo2 = $("[name='rodo2']:checked").val();
        }
        if ($("[name='rodo3']:checked").length) {
            rodo3 = $("[name='rodo3']:checked").val();
        }
        if ($("[name='rodo4']:checked").length) {
            rodo4 = $("[name='rodo4']:checked").val();
        }

        $.ajax({
//            url: "kontakt/formularz",
            url: "newsletter/subscribe/store",
            method: "POST",
            dataType: "json",
            data: {
                "_token": $input_token.val(),
                email: $input_email.val(),
                rodo1: rodo1,
                rodo2: rodo2,
                rodo3: rodo3,
                rodo4: rodo4
            }
        }).always(function(response) {
            if (response.status == 200) {
                $errors.hide();
                //$success.show();
                $('.newsletter-form [data-modal="success"]').html(response.text);
                console.log(response);
            } else {
                $errors.each(function() {
                    if (response.responseJSON[$(this).data('modal-error')]) {
                        $(this).text(response.responseJSON[$(this).data('modal-error')]).show();
                    } else {
                        $(this).hide();
                    }
                });
            }
        });
    });
});


//GOOGLE MAPS
function initMap() {

    //var uluru = {lat: 50.037756, lng: 22.005512};
    var uluru2 = {lat: 50.027402, lng: 22.018140};

    //var center_map = {lat: 50.035367, lng: 22.011762};
    var center_map = {lat: 50.027402, lng: 22.018140};

    //var content1 = "<div class='google-maps-pin google-maps-pin_first'><div class='google-maps-pin__title'>Oddział 1</div></div>";
    //var content2 = "<div class='google-maps-pin google-maps-pin_second'><div class='google-maps-pin__title'></div></div>";
    var content2 = "<div class='google-maps-pin google-maps-pin_second'><div></div></div>";

    var styles = new google.maps.StyledMapType([
        {
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#f5f5f5"
                }
            ]
        },
        {
            "elementType": "labels.icon",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#616161"
                }
            ]
        },
        {
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                    "color": "#f5f5f5"
                }
            ]
        },
        {
            "featureType": "administrative.land_parcel",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#bdbdbd"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#eeeeee"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#757575"
                }
            ]
        },
        {
            "featureType": "poi.park",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#e5e5e5"
                }
            ]
        },
        {
            "featureType": "poi.park",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#9e9e9e"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#ffffff"
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#757575"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#dadada"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#616161"
                }
            ]
        },
        {
            "featureType": "road.local",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#9e9e9e"
                }
            ]
        },
        {
            "featureType": "transit.line",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#e5e5e5"
                }
            ]
        },
        {
            "featureType": "transit.station",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#eeeeee"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#c9c9c9"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#9e9e9e"
                }
            ]
        }
    ],
            {
                name: "Style"
            });

    var map = new google.maps.Map(document.getElementById('map'), {
        zoom: 14,
        center: center_map,
        scrollwheel: false,
        mapTypeControlOptions: {
            mapTypeIds: []
        }
    });

    map.mapTypes.set("styles", styles);
    map.setMapTypeId('styles');

    /*infowindow1 = new google.maps.InfoWindow({
        content: content1
    });*/
    infowindow2 = new google.maps.InfoWindow({
        content: content2
    });

    /*var marker = new google.maps.Marker({
        position: uluru,
        map: map,
        animation: google.maps.Animation.DROP,
    });*/

    var marker2 = new google.maps.Marker({
        position: uluru2,
        map: map,
        animation: google.maps.Animation.DROP
    });

    //infowindow1.open(map, marker);
    infowindow2.open(map, marker2);

    //map.addListener('tilesloaded', function() {
        /*$('.google-maps-pin_first')
                .parent()
                .parent()
                .parent()
                .parent()
                .find('>div:first-child>div:last-child')
                .css({
                    backgroundImage: "url('../../../img/oddzial-1.png')",
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '100% 100%',
                });*/


        $('.google-maps-pin_second')
                .parent()
                .parent()
                .parent()
                .parent()
                .find('>div:first-child>div:last-child')
                .css({
//                    backgroundImage: "url('../../img/oddzial-2.png')",
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '100% 100%',
                });
    //});
}

$(function() {

    if ($('.offers-search').length != 0)
    {
        var $window = $(window);

        var $element = $('.offers-search');
        var $left_column = $('.left-column');

        var element_height = $element.outerHeight();

        var left_column_height = $left_column.height();
        var left_column_top_position = $left_column.offset().top;
        var left_column_bottom_position = left_column_top_position + left_column_height;

        function setVariables() {
            element_height = $element.outerHeight();
            left_column_height = $left_column.height();
            left_column_top_position = $left_column.offset().top;
            left_column_bottom_position = left_column_top_position + left_column_height;
        }

        function check_if_scrolled()
        {
            var window_top_position = $window.scrollTop();
            if (window_top_position + 80 > left_column_top_position
                    && window_top_position + 80 + element_height < left_column_bottom_position
                )
            {
                $element.css({
                    position: 'fixed',
                }).offset({top: window_top_position + 80});
            } else if (window_top_position < left_column_top_position) {
                $element.css('top', 0);
                $element.css({
                    position: 'static',
                })
            } else {
                $element.css({
                    position: 'absolute',
                })
                $element.css('top', left_column_bottom_position - element_height - left_column_top_position);
            }
        }

        $window.on('scroll', function() {
            check_if_scrolled();
        });
        $window.on('resize', function() {
            setVariables();
            $window.trigger('scroll');
        });
    }
});

$(function() {
    jQuery.expr[':'].regex = function(elem, index, match) {
        var matchParams = match[3].split(','),
                validLabels = /^(data|css):/,
                attr = {
                    method: matchParams[0].match(validLabels) ?
                            matchParams[0].split(':')[0] : 'attr',
                    property: matchParams.shift().replace(validLabels, '')
                },
                regexFlags = 'ig',
                regex = new RegExp(matchParams.join('').replace(/^\s+|\s+$/g, ''), regexFlags);
        return regex.test(jQuery(elem)[attr.method](attr.property));
    }
    var host = window.location.host;
    var path = window.location.pathname;
    var reg = host + '/[A-Za-z]{2}' + path + '$';

    $(':regex(href,' + reg + ')', '.navbar-nav').parent().addClass('active').parents('li').addClass('active');
});

$(function() {

    var $animation_element = $('#navigation');
    var $window = $(window);

    function check_if_in_view() {

        var window_top_position = $window.scrollTop();

        if (window_top_position >= 100)
        {
            $animation_element.addClass('navigation-scroll');
        } else
        {
            $animation_element.removeClass('navigation-scroll');
        }
    }

    $window.on('scroll resize', check_if_in_view);
    $window.trigger('scroll');
});