var Ready = false;
document.ready = create();

function create() {
    Ready = true;
}

function f1(a) {
    oBj = document.Kalkulator;
    if (!isReady())
        return false;
    for (var i = 0; i < oBj.rodzaj_mieszkania.length; i++) {
        oBj.rodzaj_mieszkania[i].checked ? changeData(i) : false;
    }
}

function changeData(i) {
    oBj = document.Kalkulator;
    switch (i) {
        case 0:
            oBj.oplata_sadowa.value = '0.00';
            break;
        case 1:
            oBj.oplata_sadowa.value = '200.00';
            break;
        case 2:
            oBj.oplata_sadowa.value = '200.00';
            break;
    }
    refreshCalc();
}

function isReady() {
    if (!isReady) {
        alert('Nie skończono ładowania strony, proszę poczekać!');
        return false;
    } else {
        return true;
    }
}

function refreshCalc() {
    oBj = document.Kalkulator;
    var CT = 0;
    CT = oBj.cena_transakcyjna.value;
    var reg = new RegExp("\\d{1,}");
    var PN = oBj.prowizja_nieruchomosci.value;

    if (!IsNumeric(PN) || PN < 0) {
        PN = 2;
        oBj.prowizja_nieruchomosci.value = 2;
    }
    if (!IsNumeric(CT))
        clearForm();

    var OpS;

    var TN = 0;
    var PO = 0;
    var OS = 0;
    oBj.rodzaj_mieszkania[2].checked ? OpS = true : OpS = false;

    PO = oBj.podatek_od_czynnosci.value = CT * 2 / 100;
    OS = oBj.oplata_sadowa.value * 1;
    oBj.podatek_od_czynnosci.value = round2(PO);
    if (!OpS && CT > 0) {
        if ((CT > 0) && (CT <= 5000))
            TN = 200;
        else
        if ((CT > 5000) && (CT <= 15000))
            TN = (200 + ((CT - 5000) * 0.03)) / 2;
        else
        if ((CT > 15000) && (CT <= 30000))
            TN = (500 + ((CT - 15000) * 0.02)) / 2;
        else
        if ((CT > 30000) && (CT <= 60000))
            TN = (800 + ((CT - 30000) * 0.01)) / 2;
        else
        if ((CT > 60000) && (CT <= 1000000))
            TN = (1100 + ((CT - 60000) * 0.005)) / 2;
        else
        if (CT > 1000000)
            TN = (5800 + ((CT - 1000000) * 0.0025)) / 2;
    } else
    if (CT > 0) {
        if ((CT > 0) && (CT <= 5000))
            TN = 200 / 2;
        if ((CT > 5000) && (CT <= 15000))
            TN = (200 + (CT - 5000) * 0.03);
        if ((CT > 15000) && (CT <= 30000))
            TN = (500 + (CT - 15000) * 0.02);
        if ((CT > 30000) && (CT <= 60000))
            TN = (800 + (CT - 30000) * 0.01);
        if ((CT > 60000) && (CT <= 1000000))
            TN = (1100 + (CT - 60000) * 0.005);
        if (CT > 1000000)
            TN = (5800 + (CT - 1000000) * 0.0025);
    } else
        TN = 0.00;

    TN = (Math.round(TN * 100)) / 100;
    oBj.taksa.value = round2(TN);
    var TV = oBj.taksa_vat.value = round2(TN * 0.23);

    var PR_B = oBj.prowizja_nieruchomosci_brutto.value = round2(CT * (PN / 100) + (CT * (PN / 100)) * 0.23);
    var PR_N = oBj.prowizja_nieruchomosci_netto.value = round2(CT * (PN / 100));
    oBj.vat.value = round2((CT * (PN / 100)) * 0.23);
    var SO = TN + PO + OS + PR_N + TV;
    SO *= 1;
    CT *= 1;
    oBj.suma_oplat.value = round2(SO);
    oBj.suma_do_zaplaty.value = round2(SO + CT);
    return false;
}

function IsNumeric(sText) {
    var ValidChars = "0123456789.";
    var IsNumber = true;
    var Char;

    for (i = 0; i < sText.length && IsNumber == true; i++) {
        Char = sText.charAt(i);
        if (ValidChars.indexOf(Char) == -1) {
            IsNumber = false;
        }
    }
    return IsNumber;
}

function clearForm() {
    oBj = document.Kalkulator;
    oBj.cena_transakcyjna.value = 0.00;
    refreshCalc();
    oBj.cena_transakcyjna.value = 'niepoprawne dane';
}

function round2(liczba) {
    liczba = liczba * 100;
    var wynik = Math.round(liczba) / 100;
    return wynik;
}
